@import 'src/scss/vars.scss';

body {

  .p-field-custom {
    padding: 12px 0;
    margin-bottom: 0;
  }

  .p-float-label {
    display: block;

    & .p-inputwrapper-filled~label,
    & .p-inputwrapper-focus~label,
    & input.p-filled~label,
    & input:focus~label,
    & textarea.p-filled~label,
    & textarea:focus~label {
      top: 15px;
      font-size: 10px;
    }

    & .p-inputwrapper-filled~label,
    & input.p-filled~label,
    & textarea.p-filled~label {
      color: #848998;
    }

    & .p-inputwrapper-focus~label,
    & input:focus~label,
    & textarea:focus~label {
      color: #4D71FE;
    }

    label {
      margin: 0;
      top: 50%;
      left: 15px;
      font-size: 14px;
      line-height: 17px;
      color: #848998;
      transform: translateY(-50%);
      transition: top .3s ease, font-size .3s ease, line-height .3s ease, color .3s ease;
      z-index: 2;
    }
  }

  .p-calendar {
    width: 100%;

    &.p-calendar-w-btn {

      .p-inputtext {
        flex: 1 1 auto;
        width: 1%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.p-calendar-disabled {

      .p-inputtext {
        background-color: $lightest-gray;
        border-color: $disabled;
      }

      .p-datepicker-trigger {
        background-color: $lightest-gray;
        border-color: $disabled;

        &:hover {
          background-color: $lightest-gray;
          border-color: $disabled;
        }
      }
    }

    .p-datepicker-trigger {
      width: 32px;
      margin-left: -1px;
      background: $transparent;
      border-left: none;
      border: 1px solid #E3E4E9;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      transition: border-color .3s ease;
      z-index: 1;

      &:hover {
        background: $transparent;
        border-color: $blue;
      }

      &:focus {
        box-shadow: none;
      }

      .p-icon-wrapper {

        &::before {
          content: "calendar_month";
          font-family: "Material Icons Outlined";
          font-size: 18px;
          color: $blue;
        }

        svg {
          display: none;
        }
      }
    }
  }

  .p-inputtext {
    height: 48px;
    width: 100%;
    padding: 26px 14px 11px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #5E616D;
    background-color: $white;
    border: 1px solid #E3E4E9;
    border-radius: 8px;
    transition: box-shadow .3s ease;
    z-index: 1;
    display: block;

    &:hover:not(.p-state-error) {
      border-color: #E3E4E9;
    }

    &:enabled:focus:not(.p-state-error) {
      outline: none;
      border-color: #E3E4E9;
      box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                  0 10px 40px 0 rgba(239,239,239,0.3);
    }
  }

  .p-datepicker.p-component {
    min-width: 308px !important;
    padding: 10px 12px 5px;
    border-radius: 16px;
    box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2), 0 10px 40px 0 rgba(239,239,239,0.3);
    background-color: #fff;
    border: none;
  
    .p-datepicker-header {
      padding: 0 7px;
      justify-content: space-around;
  
      // left & right buttons
      .p-datepicker-prev,
      .p-datepicker-next {
        color: #4a74ff;
        opacity: .7;
  
        &:hover {
          opacity: 1;
        }
  
        &:focus {
          box-shadow: none;
        }
  
        span {
          font-size: 12px;
        }
      }

      .p-datepicker-title {
        margin: 0;

        // month and year pickers (label)
        .p-datepicker-month,
        .p-datepicker-year {
          padding: 0 5px;
          font-family: $font-family-primary;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          color: #3D3F46;
        }

        .p-datepicker-decade {
          font-family: $font-family-primary;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: #3D3F46;
        }
      }
    }

    .p-yearpicker {
      width: 260px;
      font-family: $font-family-primary;
      font-size: 14px;

      .p-yearpicker-year {
        width: 130px;
        padding: 2px 0;

        &.p-highlight {
          background-color: #4D71FE;
        }
      }
    }

    .p-monthpicker {
      width: 260px;
      font-family: $font-family-primary;
      font-size: 14px;
    }
  
    .p-datepicker-calendar-container {
  
      .p-datepicker-calendar {
        margin: 8px 0 2px;
  
        thead {
          color: rgba(#000, .4);
          background-color: none;
          border-bottom: 5px solid #fff;
  
          th {
            font-size: 12px;
            padding: 5px;
            background-color: #f4f6f8;
            text-align: center;
  
            span {
              font-family: $font-family-primary;
              font-size: 12px;
              font-weight: 400;
              color: #848998;
              text-align: center;
            }
          }
        }
  
        tbody {
  
          td {
            padding: 1px;
            font-family: $font-family-primary;
            font-size: 14px;
            font-weight: 700;
            color: #3D3F46;

            &.p-datepicker-today {

              .p-element {
                color: #3d3f46;
                background-color: $transparent;

                &.p-highlight {

                  &::before {
                    background-color: $white;
                  }
                }

                &::before {
                  display: inline-block;
                  content: '';
                  width: 4px;
                  height: 4px;
                  position: absolute;
                  bottom: 2px;
                  left: 50%;
                  border-radius: 50%;
                  background-color: #4D71FE;
                  transform: translateX(-50%);
                }
              }
            }

            .p-element {
              width: 30px;
              height: 30px;
              margin: 1px auto;
              border-radius: 50%;
              border: 1px solid transparent;

              &:hover {
                background-color: #eaeaea;
              }

              &.p-highlight {
                color: $white;
                background-color: #4D71FE;
                box-shadow: 0 0 10px 0 rgba(123,123,123,0.2), 0 5px 40px 0 rgba(215,224,255,0.3);
              }
            }
          }
        }
      }
    }
  
    .p-timepicker {
      display: flex;
      justify-content: center;
      padding: 2px 5px;
      border-top: 1px solid rgba(#d8dae2, .5);

      button.p-element {
        color: #4a74ff;
        opacity: .7;
  
        &:hover {
          opacity: 1;
        }
  
        &:focus {
          box-shadow: none;
        }

        &:last-child {
          margin-top: 2px;
        }
  
        .p-icon {
          width: 12px;
          height: 12px;
        }
      }
  
      .p-hour-picker {
        width: 30%;
        margin-left: 0;
      }
  
      .p-minute-picker {
        width: 30%;
      }
  
      .p-ampm-picker {
        width: 30%;
        margin-left: 0;
      }
  
      .p-hour-picker,
      .p-minute-picker,
      .p-ampm-picker {
        position: relative;
  
        &::before {
          content: '';
          width: 36px;
          position: absolute;
          top: 21px;
          bottom: 21px;
          left: 50%;
          background-color: #f4f6f8;
          border-radius: 8px;
          transform: translateX(-50%);
          z-index: 1;
        }

        &.p-ampm-picker {

          &::before {
            background-color: $transparent;
            border: 2px solid #F4F6F8;
          }
        }
  
        & > span {
          display: inline-block;
          position: relative;
          padding: 6px 0 5px;
          font-family: $font-family-primary;
          font-size: 14px;
          font-weight: 700;
          color: #4a74ff;
          background-color: rgba(#fff, 0);
          z-index: 2;
        }
  
        a {
          position: relative;
          z-index: 2;
  
          &:last-child {
            margin-top: 0;
          }
  
          span {
            font-size: 15px;
            color: #4a74ff;
          }
        }
      }
  
      .p-separator {
        width: 6px;
        min-width: 0;
        position: relative;
  
        &::before,
        &::after {
          content: "";
          display: inline-block;
          width: 2px;
          height: 2px;
          position: absolute;
          left: 50%;
          border-radius: 50%;
          transform: translateX(-50%);
          background-color: rgb(61, 63, 70);
        }
  
        &::before {
          top: 2px;
        }
  
        &::after {
          bottom: 2px;
        }
  
        a,
        span {
          display: none;
        }
      }
    }

    .p-datepicker-buttonbar {
      padding: 3px 28px 0;
      border-top: 1px solid rgba(#d8dae2, .5);

      button.p-element {
        font-size: 14px;
        color: #007ad9;
      }
    }
  }
}


.chart-date-picker {

  .p-calendar {

    &::before {
      font-family: "Material Icons";
      content: "\ebcc";
      font-size: 20px;
      color: $primary;
      position: absolute;
      top: 5px;
      left: 10px;
      z-index: 10;
    }
  }

  input {
    height: 40px;
    padding: 10px 10px 10px 32px;
    max-width: 162px;
    cursor: pointer;
    text-align: center;
    border: 2px solid $secondary;
    color: $primary;
    background-color: #f7f9ff;
    font-family: europa, Helvetica, Arial, sans-serif;
    font-size: 14px;

    &:hover {
      border: 2px solid $primary !important;
    }
  }        
}