@import "src/scss/vars.scss";

.widgets-area {
  margin-top: 32px;

  .widgets-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;

    .widget-wrapper {
      // fix for overflowing flex items
      min-width: 0;
      padding: 10px;
      position: relative;

      &:hover {

        .widget-editable {
          opacity: 1;
        }
      }

      // this is used when a new widget is being developed
      // to avoid having blank space in the dashboard whenever the dashboard config is changed
      // because the dashboard config is shared between local and live environments
      .widget-unknown {
        width: 100%;
        height: 381px;
        padding: 20px;
        // striped background
        background: repeating-linear-gradient(45deg, $white 10px, $white 60px, $lighter-gray 87px);
        border-radius: 12px;
        box-shadow: 0px 10px 40px -10px rgba(26, 26, 26, 0.2);

        p {
          font-size: 14px;
          color: $text-color-standard;
        }
      }

      .widget-editable {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20px;
        right: 20px;
        opacity: .5;
        transition: opacity .3s ease;
        z-index: 32;

        .button {
          justify-content: center;
          margin-bottom: 5px;
        }
      }

      .widget {
        height: 381px;
        position: relative;
        background-color: $white;
        border-radius: 12px;
        box-shadow: 0px 10px 40px -10px rgba(26, 26, 26, 0.2);

        &.loader-shown {

          .widget-loader {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
          }
        }

        &.failed-to-load {

          .widget-failed-to-load {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
          }
        }

        .widget-failed-to-load {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $white;
          visibility: hidden;
          opacity: 0;
          transition: opacity .3s ease-out, visibility .3s ease-out;
          pointer-events: none;
          z-index: 30;

          .content {
            height: calc(100% - 22px);
            display: flex;
            justify-content: center;
            align-items: center;

            .content-inner {
              text-align: center;

              .icon {
                display: inline-block;
                height: 58px;
                width: 58px;
                padding-top: 8px;
                font-size: 30px;
                color: $white;
                background-color: #F6B81C;
                border: 5px solid #FFEBBF;
                border-radius: 50%;
              }

              .title {
                margin-top: 21px;
                margin-bottom: 8px;
                font-size: 24px;
                line-height: 28px;
                color: $text-color-standard;
              }

              .text-content {
                margin: 0;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.4px;
                color: $grey-text;
              }
            }

            .button {
              display: block;
              position: absolute;
              right: 12px;
              bottom: 12px;
              left: 12px;
            }
          }
        }

        .widget-loader {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $white;
          visibility: hidden;
          opacity: 0;
          transition: opacity .3s ease-out, visibility .3s ease-out;
          pointer-events: none;
          z-index: 20;

          .widget-loader-header {
            display: flex;
            gap: 8px;
            padding: 16px;
            position: relative;

            .loader-icon {
              width: 24px;
              height: 24px;
            }

            .loader-title {
              width: 85px;
              height: 24px;
            }

            .loader-right-icon {
              width: 24px;
              height: 24px;
              position: absolute;
              top: 16px;
              right: 16px;
            }
          }

          .loader-element {
            animation-name: pulse;
            animation-iteration-count: infinite;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            border-radius: 3px;
          }
        }
      }
    }
  }
}