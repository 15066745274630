// fix for paginator dropdown in table that appears in modal - both appended to body
.dialog-with-table {

    & + .p-component .p-dropdown-panel {
        background-color: $white;
        display: table;
        box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
        0 10px 40px 0 rgba(239, 239, 239, 0.3);
        border: none;
        border-radius: 8px;

        .p-dropdown-items-wrapper {
            max-height: 170px;

            .p-dropdown-items {
                white-space: nowrap;
                border: none;
                padding: 17px 20px;
                max-height: 170px;
                
                p-dropdownitem {
                    padding: 4px 15px 4px 0;
                }

                .p-dropdown-item {
                    color: $grey-text;
                    font-size: 16px;
                    background-color: transparent;
                    line-height: 19px;
                    font-family: $font-family-primary;
                    padding-left: 0;
                    border-radius: 2px;

                    &.p-focus {
                        outline: $outline-focused;
                    }
                    
                    &:hover {
                        color: $primary;
                        background-color: transparent;
                    }

                    &.p-highlight {
                        color: $secondary-hover;
                    }

                    &::after {
                        content: " Rows";
                    }
                }
            }
        }
    }

    & .search-button-open {
        max-width: 400px;
    }

}

.dialog {

    &.hidden-close-dialog {

        .p-dialog-header {
            display: none !important;
        }
    }

    &.p-dialog {
        border: none;
        padding: 0;
        border-radius: 8px;
        background-color: $white;

        .p-dialog-header {
            padding: 32px 40px 0;
            // TODO - dirty fix, check if this breaks other dialogs
            margin-bottom: -32px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: none;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

          .p-dialog-title {
            font-size: 20px;
            font-family: $font-family-primary;
          }

        }

        .p-dialog-content {
            padding: 47px 40px 32px;
            overflow: auto;
            border-radius: 8px;

            .generic-label {
                padding: 5px 0;
            }

            .p-inputtext {
                font-family: $font-family-primary;
            }
        }

        // fix for textarea overflow
        &.dialog-with-textarea {
            
            .p-dialog-footer {
                padding-top: 20px;
            }
        }

        .p-dialog-footer {
            padding: 0 40px 32px;
            border-top: none;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            button {
                margin: 0;
            }
            
            button:not(:first-child) {
                margin-left: 24px;
            }
        }
    }
}
