@import "src/scss/vars.scss";

.button {
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  font-family: $font-family-primary;
  padding: 14px 23px;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  vertical-align: middle;
}

.button-primary {

  &:enabled {
    position: relative;
    box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
                0 10px 40px 0 rgba(74, 116, 255, 0.3);
    border: none;
    color: $white;
    z-index: 1;

    &::before,
    &::after {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 8px;
    }

    &::before {
      background-image: linear-gradient(#4a74ff 0.11%, #4e6ffe 100%);
      z-index: -2;
    }

    &::after {
      background-color: $primary-hover;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
      z-index: -1;
    }

    &:hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:active {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:focus-visible {
      outline: $outline-focused;
      // outline: none;

      // &::after {
      //   opacity: 1;
      //   visibility: visible;
      // }
    }

  }

  &:disabled {
    background-image: none;
    background-color: $disabled;
    border-color: transparent;
    border: none;
    color: $unselected-light;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      background-color: $disabled;
    }

    // disabled state while showing enabled state
    &.disabled-soft {
      position: relative;
      box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
                  0 10px 40px 0 rgba(74, 116, 255, 0.3);
      border: none;
      color: $white;
      z-index: 1;

      &::before,
      &::after {
        display: inline-block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 8px;
      }

      &::before {
        background-image: linear-gradient(#4a74ff 0.11%, #4e6ffe 100%);
        z-index: -2;
      }

      &::after {
        background-color: $primary-hover;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease, background-color 0.3s ease;
        z-index: -1;
      }
    }
  }

  &--medium {
    height: 42px;
    padding: 12px 15px;
    line-height: 16px;
    font-size: 14px;
  }

  &--smaller {
    height: 36px;
    padding: 8px 10px;
    font-size: 15px;
    display: flex;
  }

  &.button-login {
    width: 100%;
    height: 42px;
    padding: 8px 23px;
    font-size: 14px;
    line-height: 18px;
  }
}

.button-secondary {
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:enabled {
    background-color: $white;
    border: 1px solid $secondary;
    color: $secondary-hover;

    &:hover {
      background: $white;
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &:active {
      border-color: $secondary-pressed;
      background-color: $white;
      color: $secondary-hover;
    }

    &:focus-visible {
      outline: $outline-focused;
      // border-color: $secondary-pressed;
      // box-shadow: 0 0 0 0.2rem $white;
    }

    :not(:focus) {
      background-color: $white;
    }
  }

  &:disabled {
    background-image: none;
    background-color: $white;
    border: 1px solid $secondary-disabled;
    color: $unselected-light;
    cursor: not-allowed;
    box-shadow: none;
  }

  &--medium {
    height: 42px;
    padding: 8px 10px;
    font-size: 14px;
  }

  &--smaller {
    height: 36px;
    padding: 8px 10px;
    font-size: 15px;
    display: flex;
  }

  &--red {

    &:enabled {
      border: 1px solid $urgent-border;
      color: $urgent;

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }

      &:hover {
        border-color: $urgent-hover;
        color: $urgent-hover;
      }

      &:active {
        border-color: $urgent-pressed;
        color: $urgent-hover;
      }

      &:focus {
        border-color: $urgent-pressed;
      }

      &.marked {
        color: $white;
        background-color: $urgent;
        border-color: $urgent-border;
      }
    }

    &--full {
      
      &:enabled {
        border: 1px solid $urgent-border;
        color: $urgent;
        transition: color .3s ease, border-color .3s ease, background-color .3s ease;

        &:hover,
        &:focus {
          color: $white;
          background-color: $urgent;
          border-color: $urgent-border;
        }

        &:active {
          color: $white;
          background-color: darken($urgent, 5%);
          border-color: darken($urgent-border, 5%);
        }
      }
    }

    &:disabled {

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }
    }
  }

  &--green {

    &:enabled {
      border: 1px solid $green-border;
      color: $green-solid;

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }

      &:hover {
        border-color: $green-hover;
        color: $green-hover;
      }

      &:active {
        border-color: $green-pressed;
        color: $green-hover;
      }

      &:focus {
        border-color: $green-pressed;
      }

      &.marked {
        color: $white;
        background-color: $green-solid;
        border-color: $green-border;
      }
    }

    &:disabled {

      .material-icons {
        padding-top: 2px;
        padding-right: 6px;
        font-size: 14px;
      }
    }
  }
}

.button-delete {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 15px 23px 15px 20px;
  font-weight: normal;

  &:enabled {
    background-color: $unselected-light;
    color: $white;
    // display: flex;
    border: none;

    .material-icons {
      font-size: 19px;
      padding-right: 10px;
      padding-bottom: 2px;
    }

    &:hover {
      border: none;
      background-color: $urgent;
      box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
        0 10px 40px 0 rgba(243, 74, 74, 0.3);
    }

    &:active {
      background-color: $urgent-light;
      border: none;
      box-shadow: 0 5px 10px 0 rgba(99, 99, 99, 0.2),
        0 10px 40px 0 rgba(243, 74, 74, 0.3);
    }

    &:focus-visible {
      outline: $outline-focused;
    }

    &:visited {
      border: none;
    }
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
    background-color: $disabled;
    color: $unselected-light;
    // display: flex;

    .material-icons {
      font-size: 19px;
      padding-right: 10px;
      padding-bottom: 2px;
    }
  }

  &--medium {
    height: 42px;
    padding: 12px 15px;
    line-height: 16px;
    font-size: 14px;
  }
}

.button-w-icon {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  padding: 14px 21px 15px 20px;

  &:enabled {
    background-color: $white;
    border: 1px solid $secondary;
    color: $secondary-hover;
    display: flex;

    .material-icons {
      font-size: 16px;
      padding-right: 15px;
      padding-top: 2px;
    }

    &:hover {
      background: $white;
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &:active {
      border-color: $secondary-pressed;
      background-color: $white;
      color: $secondary-hover;
    }

    &:focus {
      outline: none;
      border-color: $secondary-pressed;
      box-shadow: 0 0 0 0.2rem $white;
    }

    &:focus-visible {
      outline: $outline-focused;
    }

    :not(:focus) {
      background-color: $white;
    }
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $secondary-disabled;
    color: $unselected-light;
    background-image: none;
    cursor: not-allowed;
    display: flex;
    border: 1px solid $disabled;

    .material-icons {
      font-size: 16px;
      padding-right: 15px;
      padding-top: 2px;
    }
  }

  &--smaller {
    height: 36px;
    padding: 7px 10px;
    font-size: 14px;

    &:enabled {

      span {
        padding-top: 2px;
        padding-right: 8px;
        font-size: 16px;
      }
    }

    &:disabled {
      
      span {
        padding-top: 2px;
        padding-right: 8px;
        font-size: 16px;
      }
    }
  }
}

.button-light {
  height: 36px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  line-height: 19px;
  font-family: $font-family-primary;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  vertical-align: middle;
  color: $text-color-standard;
  transition: border .5s ease, background-color .5s ease;

  &:focus-visible {
    outline: $outline-focused;
  }

  &--red {
    &:enabled {
      border: 1px solid $lighter-red;
      background-color: $lighter-red;

      &:hover {
        border-color: lighten($urgent-light,10%);
        background-color: lighten($urgent-light, 10%);
      }

      &:active {
        border-color: $urgent-light;
        background-color: $urgent-light;
      }

      &:focus {
        background-color: $urgent-light;
        border-color: $urgent-light;
      }

      &:focus-visible {
        background-color: $lighter-red;
        border-color: $lighter-red;
        outline: $outline-focused;
      }

      &.highlight {
        background-color: $urgent-pressed;
        border-color: $urgent-pressed;
      }
    }
  }

  &--green {
    &:enabled {
      border: 1px solid $lighter-green;
      background-color: $lighter-green;

      &:hover {
        border-color: lighten($green-border, 10%);
        background-color: lighten($green-border, 10%);
      }

      &:active {
        border-color: $green-border;
        background-color: $green-border;
      }

      &:focus {
        border-color: $green-border;
        background-color: $green-border;
      }

      &:focus-visible {
        background-color: $lighter-green;
        border-color: $lighter-green;
        outline: $outline-focused;
      }

      &.highlight {
        background-color: $green-pressed;
        border-color: $green-pressed;
      }
    }
  }

  &--yellow {
    &:enabled {
      border: 1px solid $lightest-yellow;
      background-color: $lightest-yellow;

      &:hover {
        border-color: lighten($light-yellow, 10%);
        background-color: lighten($light-yellow, 10%);
      }

      &:active {
        border-color: $light-yellow;
        background-color: $light-yellow;
      }

      &:focus {
        border-color: $light-yellow;
        background-color: $light-yellow;
      }

      &:focus-visible {
        background-color: $lightest-yellow;
        border-color: $lightest-yellow;
        outline: $outline-focused;
      }

      &.highlight {
        background-color: $yellow;
        border-color: $yellow;
      }
    }
  }

  &--gray {
    &:enabled {
      border: 1px solid $lightest-gray;
      background-color: $lightest-gray;

      &:hover {
        border-color: lighten($gray, 20%);
        background-color: lighten($gray, 20%);
      }

      &:active {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }

      &:focus {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }

      &:focus-visible {
        background-color: $lightest-gray;
        border-color: $lightest-gray;
        outline: $outline-focused;
      }

      &.highlight {
        border-color: lighten($gray, 10%);
        background-color: lighten($gray, 10%);
      }
    }
  }
}

.p-disabled:disabled {
  opacity: 1;
  cursor: not-allowed;
}

// button for toggling p-menu dropdown
.button-p-menu-toggle {
  display: inline-flex;
  height: 42px;
  padding: 0;
  background-color: $white;
  border: 2px solid $secondary;
  border-radius: 8px;
  box-shadow: none;
  transition: box-shadow .3s ease, border .3s ease;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: 2px solid $primary;
  }

  &:active {
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: 2px solid transparent;
  }

  &:focus-visible {
    outline: $outline-focused;
  }

  .label {
    display: inline-block;
    height: 34px;
    padding: 10px 5px 0px 20px;
    border: none;
    color: $primary;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }

  .icon {
    display: inline-block;
    align-self: center;
    width: 24px;
    margin-right: 10px;
    font-size: 20px;
    color: $primary;
  }
}
