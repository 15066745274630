@import "src/scss/vars.scss";

// this is for dropdowns rendered directly inside the body
.p-dropdown-panel.appended-to-body {
  max-width: 500px;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
    0 10px 40px 0 rgba(239, 239, 239, 0.3);
  border: none;
  border-radius: 8px;
  transform: translateY(5px);

  .p-dropdown-items-wrapper {
    max-height: 170px;

    .p-dropdown-items {
      white-space: nowrap;
      border: none;
      padding: 17px 10px;
      max-height: 170px;

      .p-dropdown-empty-message {
        padding: 0;
        color: $grey-text;
        font-family: $font-family-primary;
        font-size: 16px;
        line-height: 19px;
      }

      .p-dropdown-item {
        color: $grey-text;
        font-size: 16px;
        background-color: transparent;
        line-height: 19px;
        font-family: $font-family-primary;
        padding: 4px 25px 4px 10px;
        white-space: normal;
        border-radius: 2px;

        &:hover {
          color: $primary;
          background-color: transparent;
        }

        &:last-child {
          text-transform: capitalize;
        }

        &.p-focus {
          outline: $outline-focused;
        }
      }
    }
  }
}

.dropdown-generic {

  .p-dropdown {
    width: 100%;
    height: 48px;
    border: 1px solid #E3E4E9;
    box-shadow: none;
    transition: box-shadow .3s ease;

    &:hover {
      box-shadow: none;
      border: 1px solid #E3E4E9;
    }

    &:focus {
      box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                  0 10px 40px 0 rgba(239,239,239,0.3);
      border: 1px solid #E3E4E9;
    }
  
    &:active {
      box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                  0 10px 40px 0 rgba(239,239,239,0.3);
      border: 1px solid #E3E4E9;
    }
  
    .p-dropdown-label {

      &.p-inputtext {
        font-family: $font-family-primary;
        height: auto;
        padding: 22px 14px 11px;
        color: #5E616D;
        cursor: pointer;
        border: none;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
      }
    }
  
    &:not(.p-disabled) {

      &:hover {
        border: 1px solid #E3E4E9;
      }

      &.p-focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                    0 10px 40px 0 rgba(239,239,239,0.3);
        border-color: #E3E4E9;
      }
    }
  
    .p-dropdown-panel {
      top: 54px !important;
      border: 1px solid #E3E4E9;
      border-radius: 8px;
      left: -1px !important;
      right: -1px !important;
  
      .p-dropdown-items-wrapper {
        max-height: 180px;

        .p-dropdown-items {
          white-space: nowrap;
          border: none;
          padding: 12px 0;
          max-height: 180px;

          & > .p-element {
            display: block;

            &:last-child {
              padding-bottom: 12px;

              .p-dropdown-item {
                padding: 5px 18px 5px 20px;
              }
            }

            .p-dropdown-item {
              padding: 8px 18px 9px 20px;
              font-family: $font-family-primary;
              font-size: 16px;
              line-height: 19px;
              color: #848998;
              background-color: transparent;
              text-overflow: ellipsis;
              transition: background-color .3s ease;
              overflow: hidden;
  
              &:hover {
                color: #848998;
                background-color: #f3f3f3;
              }
  
              &.p-highlight {
                background-color: #e3e4e9;
  
                &:hover {
                  background-color: #e3e4e9;
                }
              }
            }
          }
        }
      }
    }
  }
}

.p-dropdown {
  height: 42px;
  border: 2px solid $secondary;
  border-radius: 8px;
  box-shadow: none;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: 2px solid $primary;
  }

  &:focus {
    // box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
    //   0 10px 40px 0 rgba(239, 239, 239, 0.3);
    // border: 2px solid transparent;
  }

  &:focus-visible {
    outline: $outline-focused;
  }

  &:active {
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: 2px solid transparent;
  }

  &.small {
    height: 36px;

    .p-dropdown-label {
      
      &.p-inputtext {
        padding-top: 8px;
      }
    }
  }

  .p-dropdown-clear-icon {
    right: 40px;
    font-size: 12px;
    color: $secondary-hover;

    & + .p-dropdown-trigger {
      margin-left: 20px;
    }
  }

  .p-dropdown-label {
    cursor: pointer;

    &.p-inputtext {
      font-family: $font-family-primary;
      height: 28px;
      padding: 10px 10px 0px 20px;
      color: $primary;
      border: none;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
    }

    &.p-placeholder {
      height: 34px;
      padding: 10px 5px 0px 20px;
      border: none;
      color: $secondary-hover;
    }
  }

  &:not(.p-disabled) {

    &:hover {
      border: 2px solid $primary;
    }

    &.p-focus {
      outline: $outline-focused;

      &:hover {
        border: 2px solid $secondary;
      }
      // outline-offset: 0;
      // box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      //   0 10px 40px 0 rgba(239, 239, 239, 0.3);
      // border-color: $white;
    }
  }

  .p-dropdown-trigger {
    margin-right: 10px;
    color: $primary;

    span {
      padding-top: 6px;

      &::before {
        content: "\e313";
        font-family: "Material Icons";
        font-size: 20px;
        position: relative;
        bottom: 2px;
      }
    }
  }

  // this is not working, because primeng doesn't add open class anymore
  &.p-dropdown-open {
    
    .p-dropdown-trigger {

      span {

        &::before {
          content: "\e316";
          font-family: "Material Icons";
          font-size: 20px;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }

  .p-dropdown-trigger-icon {
    font-size: 14px;
    padding-top: 2px;
    color: $secondary-hover;
  }

  .p-dropdown-panel {
    background-color: $white;
    top: 48px !important;
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: none;
    border-radius: 8px;

    .p-dropdown-items-wrapper {
      // max-height: 170px !important;

      .p-dropdown-items {
        white-space: nowrap;
        border: none;
        padding: 23px 10px;
        // max-height: 170px;

        & > .p-element {
  
          &:last-child {
  
            .p-dropdown-item {
              margin-bottom: 0;
            }
          }
  
          .p-dropdown-item {
            padding: 5px 10px 9px;
            margin-bottom: 8px;
          }
        }

        .p-dropdown-item {
          color: $grey-text;
          font-size: 16px;
          background-color: transparent;
          line-height: 19px;
          font-family: $font-family-primary;
          letter-spacing: .4px;
          padding-left: 0;
          border-radius: 2px;

          &.p-focus {
            outline: $outline-focused;
          }

          &:hover {
            color: $primary;
            background-color: transparent;
          }
        }
      }
    }
  }

  .p-corner-all {
    border-radius: 8px;
    background-color: $white;
  }

  &.p-disabled {

    .p-dropdown-label.p-placeholder {
      color: $unselected-light;
    }

    .p-dropdown-trigger-icon {
      color: $unselected-light;
    }
  }

  &--only-icon {

    .p-dropdown {
      height: 36px;
      width: 36px;
      border: 1px solid $secondary;

      &:not(.p-disabled) {

        &:hover {
          border: 1px solid $primary;
        }
  
        &:focus {
          border: 1px solid transparent;
        }
  
        &:active {
          border: 1px solid transparent;
        }
      }

      .p-dropdown-label {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
      }

      .p-dropdown-trigger {
        margin-right: 0;
        text-align: center;

        ::before {
          font-family: "Material Icons Outlined";
          content: "\e2c4";
          font-size: 28px;
        }

        .p-dropdown-trigger-icon {
          font-size: 28px;
          padding-left: 11px;
        }
      }

      &.p-dropdown-open {

        .p-dropdown-trigger {

          span {

            &::before {
              content: "\e2c4";
              font-family: "Material Icons Outlined";
               font-size: 28px;
            }
          }
        }
      }

      .p-dropdown-panel {

        .p-dropdown-items-wrapper {

          .p-dropdown-items {
            padding: 12px 10px;

            & > .p-element {

              &:last-child {

                .p-dropdown-item {
                  margin-bottom: 0;
                }
              }

              .p-dropdown-item {
                padding: 6px 8px 5px;
                margin-bottom: 4px;
                color: #3d3f46;
                font-weight: bold;
    
                .ng-star-inserted {
                  display: flex;
    
                  img {
                    width: 18px;
                    height: 18px;
                    opacity: 0.7;
                  }
    
                  div {
                    padding-left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

