@use '@angular/material' as mat;
@import 'vars.scss';

@include mat.core();

// blue

$my-blue: (
  50: #4D71FE,
  100: #4D71FE,
  200: #4D71FE,
  300: #4D71FE,
  400: #4D71FE,
  500: #4D71FE,
  600: #4D71FE,
  700: #4D71FE,
  800: #4D71FE,
  900: #4D71FE,
  A100: #4D71FE,
  A200: #4D71FE,
  A400: #4D71FE,
  A700: #4D71FE,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$theme-primary: mat.define-palette($my-blue, 500);
$theme-accent: mat.define-palette($my-blue, A200, A100, A400);

$my-typography: mat.define-typography-config(
  $font-family: 'europa, Helvetica, Arial, sans-serif',
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
    ),
    typography: $my-typography,
  )
);
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($my-typography);


.custom-stepper {

  .mat-stepper-horizontal-line, 
  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after {
    border-top-color: $lightest-gray;
  }

  .mat-horizontal-stepper-header-container {
    margin-bottom: 75px;
    position: relative;

    &::before {
      display: inline-block;
      position: absolute;
      content: "";
      bottom: -25px;
      right: 0;
      left: 0;
      height: 1px;
      background-color: $lightest-gray;
    }
  }

  .mat-step-header {
    width: 200px;
    border-radius: 2px !important;

    &.cdk-keyboard-focused {
      outline: $outline-focused;
    }

    &[aria-disabled="true"] {
      cursor: not-allowed;
    }

    .mat-step-icon-state-edit {
      background-color: #86D39A;
    }
  }

  .mat-step-label-selected {

    .custom-stepper-label {
      font-weight: 700;
    }
  }

  .custom-stepper-label {
    color: #3D3F46;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }

  .custom-stepper-buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 35px;
  }

  .step-container {
    margin-bottom: 25px;

    &.step-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    
      a {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-weight: bold;
        width: fit-content;
        border-radius: 2px;

        &:focus-visible {
          outline: $outline-focused;
        }
    
        mat-icon {
          color: grey;

          &.checked {
            color: #4D71FE;
          }
        }
      }
    }
  
    &.step-conflict {
  
      .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px 0px;
      }
  
      // .conflict-checkbox {
        // width: fit-content;
      // }
    }
  
    &.step-slider {

      mat-slider:has(input:focus-visible) {
        outline: $outline-focused;
        outline-offset: 20px;
        border-radius: 1px;
      }

      .slider-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .slider-wrapper {
          margin: 80px auto 80px;
          position: relative;
  
          &.slider-trait,
          &.slider-overall-impression {
            width: 100%;
  
            .slider-ticks {
    
              .slider-tick {
    
                .tick-bottom {
                  position: absolute;
                  left: 50%;
                  bottom: -20px;
                  display: inline;
                  transform: translateX(-50%);
                  color: rgba(0,0,0,.5);
                  font-weight: 500;
                }
              }
            }
          }
  
          .mat-mdc-slider {
            width: 100%;
            margin: 0;
            cursor: pointer;

            &.step-changed {

              .mdc-slider__thumb {

                .mdc-slider__value-indicator-container {
                  display: block;
                }
              }
            }
  
            // &.mat-slider-min-value {
  
            //   .mat-slider-thumb-label {
            //     background-color: $primary;
            //   }
            // }
  
            .mdc-slider__track {
              height: 3px;
  
              .mdc-slider__track--inactive {
                height: 3px;

                &::before {
                  background-color: $gray;
                  border: none;
                }
              }
  
              .mdc-slider__track--active {
                height: 3px;
                top: 0;

                .mdc-slider__track--active_fill {
                  height: 3px;
                  background-color: $primary;
                  border: none;
                }
              }
            }

            .mdc-slider__thumb {
              z-index: 1;

              .mdc-slider__value-indicator-container {
                display: none;
                left: 25px;

                .mdc-slider__value-indicator {
                  padding: 0;
                  background-color: $transparent;
                  opacity: 1;
                  transform: none;

                  &:before {
                    display: none;
                  }

                  &:after {
                    width: 28px;
                    height: 28px;
                    top: 50%;
                    left: 50%;
                    background-color: $primary;
                    border-radius: 50% 50% 0;
                    border: none;
                    transform: translate(-50%, -50%) rotate(45deg);
                    z-index: -1;
                  }

                  .mdc-slider__value-indicator-text {
                    opacity: 1;
                    transform: none;
                  }
                }
              }

              .mdc-slider__thumb-knob {
                width: 30px;
                height: 30px;
                border: 4px solid #faf9ff;
                border-radius: 50%;
                background-color: $primary;
                box-shadow: none;
                cursor: pointer;
              }

              .mat-mdc-focus-indicator {
                display: none;
              }
            }
  
            // .mat-slider-thumb-label {
            //   top: -52px;
            //   background-color: $primary;
            //   border-radius: 50% 50% 0 !important;
            //   transform: rotate(45deg) !important;
            // }
            
            // .mat-slider-thumb-label-text {
            //   opacity: 1 !important;
            // }
          }
  
          .slider-ticks {
            height: 10px;
            position: absolute;
            right: 3px;
            bottom: 0;
            left: 3px;
  
            .slider-tick {
              display: inline-block;
              position: absolute;
              user-select: none;
              pointer-events: none;
              transform: translateX(-50%);
  
              &:nth-child(1) {
                left: 0;
              }
  
              &:nth-child(2) {
                left: 20%;
              }
  
              &:nth-child(3) {
                left: 40%;
              }
  
              &:nth-child(4) {
                left: 60%;
              }
  
              &:nth-child(5) {
                left: 80%;
              }
  
              &:nth-child(6) {
                left: 100%;
              }
  
              .tick-top {
                position: absolute;
                left: 50%;
                top: -24px;
                width: 20px;
                height: 20px;
                border: 4px solid #faf9ff;
                border-radius: 50%;
                background-color: #361d7e;
                transform: translateX(-50%);
              }
            }
          }
        }

        .anchor-low,
        .anchor-high {
          font-size: 12px;
          width: 110px;
        }

        .anchor-low {
          text-align: right;
        }

        .anchor-high {
          text-align: left;
        }

      }
  
      .feedback-field {
        width: 100%;
  
        textarea {
          min-height: 100px;
        }
      }
    }
  }

  .step-errors {
    color: #f34a4a;

    ul {
      padding: 0px 0px 0px 15px;
      margin: 0px;
    }
  }

  .mat-horizontal-content-container {
    padding: 0 24px 40px 24px;
  }

  .mat-vertical-content {
    padding: 0 24px 40px 24px;
  }
}

// snackbar
.cdk-overlay-pane .mat-snack-bar-container {
  color: $text-color-standard;
  background-color: $white;
}

// material checkbox
.mat-mdc-checkbox {

  .mdc-checkbox:has(input:focus-visible) {
    outline: $outline-focused;
    border-radius: 2px;
  }

  // hover
  &:hover {

    .mdc-form-field {

      .mdc-checkbox {

        .mdc-checkbox__background {
  
          &::after {
            border-color: $secondary-hover;
          }
        }
      }
    }
  }

  // active state
  &.mat-mdc-checkbox-checked {
    
    .mdc-form-field {

      .mdc-checkbox {

        .mdc-checkbox__background {

          &::before {
            transform: scale(1);
          }
  
          &::after {
            border-color: $secondary-hover;
          }
        }
      }
    }
  }

  .mdc-form-field {

    .mdc-checkbox {

      .mdc-checkbox__background {
        width: 20px;
        height: 20px;
        bottom: auto;
        border: none;
        border-radius: 4px;
        overflow: hidden;

        &::before,
        &::after {
          display: inline-block;
          content: '';
          position: absolute;
        }
    
        &::before {
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
          background-image: linear-gradient(to bottom, $temp-blue01, $temp-blue02);
          border-radius: 50%;
          transform: scale(0);
          transition: transform .3s ease;
          z-index: 2;
        }

        // after is needed instead of border on the parent element, because it fixes a bug with one transparent pixel on the border
        &::after {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 2px solid $unselected-light;
          border-radius: 4px;
          transition: border-color .3s ease;
          z-index: 3;
        }

        .mdc-checkbox__checkmark {
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 4;
        }
      }
    }

    .mdc-label {
      margin-top: 1px;
    }
  }
}

// custom mat menu
.mat-menu-custom {

  // additional class for specificisity to override theme styles
  &.mat-mdc-menu-panel {
    background-color: $white;
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
                0 10px 40px 0 rgba(239, 239, 239, 0.3);
    border: none;
    border-radius: 8px;

    .mat-mdc-menu-content {
      padding: 16px 16px;

      .mat-mdc-menu-item {
        min-height: 45px;
        padding: 5px 8px;
        font-size: 16px;
        line-height: 19px;
        font-family: $font-family-primary;
        letter-spacing: .4px;
        color: $grey-text;
        transition: color .3s ease;
        border-radius: 2px;

        &:not([disabled]) {

          &:hover {
            color: $primary;
            background-color: $transparent;
          }

          // focus indicator
          // instead of :focus-visible
          &.cdk-keyboard-focused {
            background-color: $transparent;
            outline: $outline-focused;
          }
        }
      }
    }
  }
}
