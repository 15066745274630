@import 'src/scss/vars.scss';
@import 'src/scss/animations.scss';

/* Pre-loader css end */
/* header css start */

// fix for judging screens (or all fullscreen screens)
// and for navigation bar
// when impersonation bar is shown
// and when notification bar is shown
.notification-shown,
.impersonation-bar-shown,
.notification-impersonation {

    .custom-navbar-outer-wrapper {
        transform: translateY(50px);

        & + .custom-main-outer-wrapper {
            margin-top: 50px;
        }
    }

    .custom-main-outer-wrapper {

        .fullscreen,
        .fullscreen-modal {
            padding-top: 50px;
        }
    }
}

.custom-navbar-outer-wrapper {
    width: 100vw;
    padding-right: 13px;
    position: fixed;
    top: 0;
    // right: 0;
    left: 0;
    transition: transform .3s ease, background-color .3s ease, box-shadow .3s ease;
    z-index: 20;

    &.scrolled-solo {
        background-color: $white;
        box-shadow: 10px 10px 30px 0 rgba(174, 174, 192, 0.4);
    }

    &.on-top {
        z-index: 30;

        .main-nav {
            animation-name: platformFadeIn;
            animation-duration: .6s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-timing-function: ease;
        }
    }
}

.pcoded-header {

    .navbar-nav {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        display: inline-block;

        > li {
            line-height: 56px;
            display: inline-block;
            padding: 0 12px;

            > a,
            .dropdown-toggle {
                > i {
                    font-size: 16px;
                }
            }

            .nav-link {
                padding: 0;
            }

            &:first-child {
                padding-left: 25px;
            }

            &:last-child {
                padding-right: 25px;
            }
        }
    }

    .mr-auto {
        .dropdown-menu {
            margin-left: -20px;
        }
    }

    .ml-auto {
        float: right;

        .dropdown-menu {
            margin-right: -20px;
        }
    }

    .dropdown {
        .dropdown-toggle {
            line-height: 56px;
            display: inline-block;
            padding-right: 5px;

            &:after {
                display: none;
            }
            // &:before {
            //     content: "";
            //     width: 30px;
            //     height: 30px;
            //     position: absolute;
            //     background: rgba(255,255,255,0.25);
            //     top: calc(#{56px/2} - 17px);
            //     left: -8px;
            //     border: none;
            //     border-radius: 50%;
            // }
        }

        .dropdown-menu {
            position: absolute;
            min-width: 250px;

            li {
                line-height: 1.2;

                a {
                    padding: 10px;
                    font-size: 14px;
                }

                &.active,
                &:active,
                &:focus,
                &:hover {
                    a {
                        color: $theme-font-color;
                    }
                }
            }
        }

        .notification {
            width: 350px;
            padding: 0;
            line-height: 1.4;
            overflow: hidden;

            a {
                color: $theme-font-color;

                &:hover {
                    color: $theme-heading-color;
                }
            }

            .noti-head {
                background: $menu-dark-background;
                padding: 15px 20px;

                h6 {
                    color: #fff;
                }

                a {
                    color: #fff;
                    text-decoration: underline;
                    font-size: 13px;
                }
            }

            .noti-body {
                padding: 0;
                position: relative;
                height: 300px;

                img {
                    width: 40px;
                    margin-right: 20px;
                }

                li {
                    padding: 15px 20px;
                    transition: all 0.15s ease-in-out;

                    &.n-title {
                        padding-bottom: 0;

                        p {
                            margin-bottom: 5px;
                        }
                    }

                    &.notification:hover {
                        background: transparentize($primary-color,0.9);
                    }

                    p {
                        margin-bottom: 5px;
                        font-size: 13px;

                        strong {
                            color: #222;
                        }
                    }

                    .n-time {
                        font-size: 80%;
                        float: right;
                    }
                }
            }

            .noti-footer {
                border-top: 1px solid #f1f1f1;
                padding: 15px 20px;
                text-align: center;
                background: $theme-background;

                a {
                    text-decoration: underline;
                    font-size: 13px;
                }
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                li {
                    padding: 20px 15px;
                }
            }
        }

        .profile-notification {
            padding: 0;
            line-height: 1.4;
            overflow: hidden;

            .pro-head {
                color: #fff;
                padding: 15px;
                position: relative;
                background: $menu-dark-background;

                img {
                    width: 40px;
                    margin-right: 10px;
                }

                .dud-logout {
                    color: #fff;
                    padding-right: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .pro-body {
                padding: 20px 0;
                margin-bottom: 0;
                list-style: none;

                li {
                    margin: 0 10px;
                    border-radius: 2px;

                    a {
                        color: $theme-font-color;
                        font-size: 14px;
                        padding: 10px;

                        i {
                            margin-right: 10px;
                        }
                    }

                    &.active,
                    &:active,
                    &:focus,
                    &:hover {
                        background: $primary-color;
                        box-shadow: 0 15px 8px -9px rgba(0, 0, 0, 0.25);

                        > a {
                            color: #fff;
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}
/* header css end */

/* main content start */

.custom-main-outer-wrapper {
    position: relative;
    transition: margin .3s ease;
    z-index: 21;
}

.pcoded-content {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 384px);
    padding: 46px 40px 64px 40px;

    @media screen and (max-width: 1183px) {
        padding: 25px 25px 25px 50px;
    }

    @media print {
        padding: 0;
    }

    .pcoded-inner-content {
        width: 100%;

        .main-body {
            display: flex;
            justify-content: center;
            min-height: 100%;

            @media print {
                min-height: 0;
            }

            .page-wrapper {
                width: 100%;
            }
        }
    }
}

.main.loginPageVisible {

    .pcoded-main-container {

        &::after {
            background-color: $transparent;
            box-shadow: none;
        }

        .pcoded-content {
            padding: 0;
        }
    }
}

.pcoded-main-container {
    display: block;
    width: 100%;
    max-width: 1143px;
    margin: 0 auto;
    position: relative;
    top: 0;
    right: 0;
    clear: both;
    float: unset;
    transition: all 0.15s ease-in-out;
    z-index: 2;

    @media screen and (max-width: 1440px) {
        max-width: calc(100% - 115px);
    }

    &.dashboard-active {

        &:after {
            // transform: scale(0);
            // top: 104px;
            opacity: 0;
        }
    }

    &.no-shape {

        &::after {
            display: none;
            content: none;
        }
    }

    &::after {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        bottom: 0;
        left: 0;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 10px 10px 30px 0 rgba(174, 174, 192, 0.4);
        // transition: transform .3s ease-in-out;
        // transform: scale(1);
        opacity: 1;
        transition: opacity .3s ease-in-out;
        z-index: -1;

        @media screen and (min-width: 1440px) {
            width: auto;
            right: 0;
        }

        @media screen and (max-width: 1183px) {
            left: 20px;
        }
    }
}
/* main content end*/
/* ==========================    Rsponsive Menu  start   ====================== */
@media only screen and (max-width: 991px) {
    .pcoded-header {
        // background: $header-dark-background;
        // color: $header-dark-text-color;
        .input-group-text,
        a,
        dropdown-toggle {
            color: $header-light-text-color;

            &:hover {
                color: $primary-color;
            }
        }

        .container > .collapse:not(.show),
        > .collapse:not(.show) {
            // display: flex;
            background: #fff;
            box-shadow: 0 0 3px 0 rgba(54, 80, 138, 0.34);
            position: relative;

            .mr-auto {
                height: 56px;
                display: flex;
                justify-content: center;

            }

            .ml-auto {
                margin-left: auto;
                margin-right: 0 !important;
                // display: none;
            }
        }

        .dropdown-menu,
        .dropdown-menu a,
        .search-close .input-group-text {
            color: $header-dark-background;
        }

        .m-header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 56px;

            .mob-toggler {
                display: flex;
            }
        }

        .full-screen {
            display: none;
        }

        .navbar-nav {
            > li {
                &:last-child {
                    padding-right: 20px;
                }
            }
        }
    }

    .pcoded-main-container {
        padding-top: 0;
    }
}
@media only screen and (max-width: 575px) {
    .pcoded-header {

        .dropdown {
            &.show {
                position: static;

                a:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }

            .notification {
                width: 100%;
            }

            .dropdown-menu {
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }
        }
    }

    .pcoded-content {
        padding: 46px 20px 64px 40px;
    }

    .card {
        margin-bottom: 15px;
    }
}
/* ==========================    Rsponsive Menu  end   ====================== */
/* ====== [Box start ] ======== */

@media only screen and (max-width: 992px) {
    .container {
        padding: 0;
    }
}
/* ====== [ Box end ] ======== */