@import "scss/style";

.loading {
  overflow: hidden;

  #loader {
    display: block !important;
  }
}

::-webkit-scrollbar {
  width: 13px;
}
  
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 14px 14px transparent;
  border: solid 4px transparent;
}
  
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px $light-gray;
  border: solid 4px transparent;
  border-radius: 14px;
  transition: box-shadow .3s ease;

  &:hover {
    box-shadow: inset 0 0 14px 14px darken($light-gray, 10%);
  }

  @media print {
    display: none;
  }
}
  
::-webkit-scrollbar-button {
  display: none;
}

// cookie consent styles
#cc-main {
  --cc-font-family: #{$font-family-primary};
  --cc-btn-primary-bg: #{$primary};
  --cc-btn-primary-hover-bg: #{$primary-hover};
  --cc-btn-primary-border-color: #{$primary};
  --cc-btn-primary-hover-border-color: #{$primary};

  // TODO - update when working on responsive
  // Cookie consent (notice) should be visible even on mobile
  // this is hidden only temprorary
  @media only screen and (max-width: 992px) {
    display: none;
  }

  .cm-wrapper {

    .cm {
      max-width: 38.4rem;

      .cm__title {
        padding: 0 2.2rem;
      }

      .cm__desc {
        padding-left: 2.2rem;
        padding-right: 2.2rem;
      }

      .cm__btns {
        padding: 1.6rem 2rem;
      }
    }
  }
}

// hide cookie consent on print
@media print {

  #cc-main {
    display: none;
  }
}

// screen readers only style
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}