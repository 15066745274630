@import "src/scss/vars.scss";

// fix for sticky header when impersonating and notification bar is shown
.impersonation-bar-shown,
.notification-shown {
  
  .custom-main-outer-wrapper {

    .reg-header {

      &.on-top {

        &.on-top-with-header {
          top: 130px;
        }
      }
    }
  }
}

.reg-header {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  &.on-top {
    position: fixed;
    width: 1143px;
    top: 10px;
    left: 50%;
    background-color: $white;
    transform: translateX(-50%);
    // transition: top 0.3s ease;
    z-index: 22;

    &.on-top-with-header {
      top: 80px;
    }

    &::before {
      display: inline-block;
      content: "";
      width: 100vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 20px 20px 0 0;
      box-shadow: 10px 10px 30px 0 rgba(174, 174, 192, 0.4);
      z-index: -1;

      @media screen and (min-width: 1440px) {
        width: auto;
        right: 0;
      }
    }

    &::after {
      display: inline-block;
      content: "";
      width: 100vw;
      position: absolute;
      top: -200px;
      left: 0;
      bottom: 80%;
      background-color: $white;
      box-shadow: -15px -3px 9px 7px white;
      z-index: -2;
    }

    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: calc(100% - 115px);
    }
  }
}

.header-wrap {
  font-family: $font-family-primary;
  font-weight: bold;
  display: flex;

  &.demo-wrap {
    //TODO: This is only for KS Purposes.
    // This box-shadow should be transfered when the header gets added to it's component
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    height: 62px;
  }

  .header-title {
    font-size: 22px;
    line-height: 26px;
    padding: 18px 0px 18px 28px;
    color: $text-color-standard;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & + .progress-bar-wrap {
      padding-left: 20px;
    }
  }

  .auto-save-icon {
    padding: 8px 0;
    font-size: 14px;

    .save-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray;
    }

    .material-icons-round {
      font-size: 16px;
    }
  }

  .progress-bar-wrap {
    display: flex;
    padding-top: 24px;
    flex-grow: 1;

    .progress-bar-text {
      font-size: 12px;
      white-space: nowrap;
      color: $unselected-light;
      font-weight: normal;
    }

    .customize-progress-bar {
      width: 100%;

      &.grey {

        .p-progressbar-value {
          background-color: $scroll;
        }
      }

      &.blue {

        .p-progressbar-value {
          background-color: $secondary-hover;
        }
      }

      &.green {

        .p-progressbar-value {
          background-color: $green;
        }
      }

      &.darkgrey {

        .p-progressbar-value {
          background-color: $unselected-light;
        }
      }

      .p-progressbar {
        margin-left: 4px;
        margin-top: 7px;
        height: 4px;
        min-width: 150px;

        .p-progressbar-label {
          display: none !important;
        }
      }
    }
  }

  .next-prev-wrap {
    display: flex;
    padding: 13px 10px 13px 25px;

    &.disable-double-button {

      button:first-of-type {

        &::after {
          background-color: $disabled;
        }
      }
    }

    button {
      outline-offset: -3px;
    }

    button:first-of-type {
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:after {
        content: "";
        background-color: $secondary;
        position: absolute;
        margin-left: 9px;
        margin-top: 3px;
        height: 18px;
        width: 1px;
      }
    }

    button:last-of-type {
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .submitted-details-wrap {
    display: flex;
    max-height: 38px;
    margin: 13px 10px 13px 18px;
    border-radius: 8px;
    white-space: nowrap;

    &.submitted {
      background-color: $lighter-green;

      .deadline-details {
        margin-right: 25px;
        display: flex;
        white-space: nowrap;

        &.deadline-approaching {
          margin-right: 0;
          padding-right: 25px;
          border-radius: 8px;

          @media only screen and (max-width: 1200px) {
            height: 36px;
            padding-right: 16px;
          }
        }

        .material-icons {
          color: $green;
        }

        .submitted-text {
          color: $green;
        }
      }
    }

    &.not-submitted {
      background-color: $lighter-red;

      .deadline-details {
        display: flex;
        margin-right: 25px;
        white-space: nowrap;

        .material-icons {
          color: $urgent;
        }

        .submitted-text {
          color: $urgent;
        }
      }
    }

    &.deadline-passed {
      background-color: $lighter-red;

      .deadline-details {
        margin-right: 25px;

        .material-icons {
          color: $urgent;
        }

        .submitted-text {
          color: $urgent;
        }
      }
    }

    .material-icons {
      font-size: 22px;
      position: relative;
      top: 8px;
      left: 8px;
      right: 8px;
    }

    .submitted-details {
      position: relative;
      top: 10px;
      left: 15px;
      font-size: 12px;
      display: flex;

      .submitted-text {
        display: inline-block;
        padding-right: 4px;

        .by {
          color: $text-color-standard;
        }
      }

      .submitted-date {
        color: $text-color-standard;
        display: flex;

        span:last-of-type {
          padding-left: 5px;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .additional-icons-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    .print-icon,
    .view-icon {
      width: 40px;
    }

    .button-link {
      height: 40px;
      width: 40px;

      &:focus-visible {
        outline: $outline-focused;
        outline-offset: -2px;
      }
    }

    .material-icons-round {
      font-size: 20px;
    }
  }

  .action-button-wrap {

    button {
      margin-top: 10px;
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1200px) {

    .submitted-details-wrap {
      margin: 13px 5px;

      &.not-submitted,
      &.submitted {
        background-color: $white;

        .deadline-details {
          margin-right: 10px;
        }
      }

      .submitted-details {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 991px) {

    .customize-progress-bar {
      display: none;
    }
  }
}
